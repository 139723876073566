<script>
// import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  page: {
    title: "Create Template",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      valid: true,
      submitted: false,
      title: "Create Banner",
      items: [
        {
          text: "Create Banner",
          href: "#",
        },
        {
          text: "Banner",
          active: true,
        },
      ],
      form: {
        image: "",
        link: "",
      },
      banner: "",
    };
  },
  
  validations: {
    form: {
      image: {
        required,
      },
    },
  },
  methods: {
    chooseFiles(id) {
      document.getElementById(id).click();
    },
    onBannerChange(e) {
      this.banner = e.target.files[0];
      this.form.image = URL.createObjectURL(this.banner);
    },
    async save() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      let formData = new FormData();
      formData.append("link", this.form.link);
      formData.append("image", this.banner);
      let header = {
        "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
      };
      var result = await ApiClass.postRequest(
        "admin/banner/create",
        true,
        formData,
        null,
        header
      );
      if (result.data.status_code == 1) {
        (this.form.link = ""),
          (this.form.image = ""),
          (this.bannner = ""),
          (this.submitted = false);

        Swal.fire("Created!", result.data.message, "success");
      } else {
        Swal.fire("Error!", result.data.message, "error");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form inline @submit.prevent="save">
              <b-row>
                <b-col md="4">
                  <div class="logo_box">
                    <h6>
                      Banner Image:
                      <small class="text-muted ms-2">
                        <strong>Note :- </strong>
                        <span class="text-danger"
                          >Image Dimension 350x250 .</span
                        ></small
                      >
                    </h6>
                    <b-img
                      v-b-tooltip.hover
                      title="Tooltip directive content"
                      thumbnail
                      fluid
                      v-model.trim="form.image"
                      :src="form.image"
                      alt="Choose Image"
                      @click="chooseFiles('banner')"
                    ></b-img>

                    <input
                      hidden
                      id="banner"
                      type="file"
                      accept="image/*"
                      @change="onBannerChange"
                    />
                    <div
                      class="error"
                      v-if="submitted && !$v.form.image.required"
                    >
                      Image is required
                    </div>
                  </div>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    id="input-group-1"
                    label="Link:"
                    label-for="input-1"
                  >
                  </b-form-group>
                  <label class="sr-only" for="inline-form-input-name"
                    >Link</label
                  >
                  <b-form-input for="text" v-model="form.link"></b-form-input>
                </b-col>
                <b-col md="4" class="text-center mt-30">
                  <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>